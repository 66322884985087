/**
 * @generated SignedSource<<5a526892784059211df8f6cc1e144ea3>>
 * @relayHash 59b0795e602871c36aac16f91b27094c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/498.0.0-2025-02-14T14:08:37.801Z/loginMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
export type SupportedMFATypes = "CODE" | "%future added value";
export type UserLoginInput = {
  authHash: string;
  captchaKey?: string | null;
  captchaToken?: string | null;
  clientMutationId?: string | null;
  cookieDomain?: string | null;
  countryCode?: string | null;
  email?: string | null;
  emailLoginToken?: string | null;
  marketingOptIn?: boolean | null;
  mfaVerificationCode?: string | null;
  password?: string | null;
  registrationTrigger?: string | null;
  socialClientId?: string | null;
  socialNetworkName?: string | null;
  socialToken?: string | null;
  socialUserEmail?: string | null;
  socialUserFirstName?: string | null;
  socialUserLastName?: string | null;
  supportedMFATypes?: ReadonlyArray<SupportedMFATypes | null> | null;
};
export type loginMutation$variables = {
  input: UserLoginInput;
};
export type loginMutation$data = {
  readonly userLogin: {
    readonly confirmedTransactions: number | null;
    readonly confirmedTransactionsRecent: number | null;
    readonly emailToken: string | null;
    readonly isNewUser: boolean | null;
    readonly responseMessage: string | null;
    readonly submittedTransactions: number | null;
    readonly submittedTransactionsRecent: number | null;
    readonly user: {
      readonly createdDate: string | null;
      readonly ethereumWalletAddress: {
        readonly address: string | null;
      } | null;
      readonly isVerifiedTrade: boolean | null;
      readonly isVip: boolean | null;
      readonly preferences: {
        readonly currency: Currency | null;
        readonly measurementUnit: string | null;
        readonly preferredLocale: string | null;
      } | null;
      readonly profile: {
        readonly email: string | null;
        readonly firstName: string | null;
        readonly lastName: string | null;
      } | null;
      readonly registrationIntent: string | null;
      readonly serviceId: string | null;
      readonly userId: string | null;
      readonly verifiedTradeStatus: string | null;
    } | null;
    readonly userId: string | null;
    readonly userToken: string | null;
  } | null;
};
export type loginMutation = {
  response: loginMutation$data;
  variables: loginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v3 = {
  "alias": "userId",
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVerifiedTrade",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVip",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "verifiedTradeStatus",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registrationIntent",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Profile",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "UserPreferences",
  "kind": "LinkedField",
  "name": "preferences",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "measurementUnit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preferredLocale",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailToken",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userToken",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "responseMessage",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isNewUser",
  "storageKey": null
},
v17 = {
  "kind": "Literal",
  "name": "viewName",
  "value": "buyerTracking.submitted"
},
v18 = {
  "alias": "submittedTransactions",
  "args": [
    (v17/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "transactionsCount",
  "storageKey": "transactionsCount(viewName:\"buyerTracking.submitted\")"
},
v19 = {
  "kind": "Literal",
  "name": "viewName",
  "value": "buyerTracking.confirmed"
},
v20 = {
  "alias": "confirmedTransactions",
  "args": [
    (v19/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "transactionsCount",
  "storageKey": "transactionsCount(viewName:\"buyerTracking.confirmed\")"
},
v21 = {
  "kind": "Literal",
  "name": "monthsBack",
  "value": 6
},
v22 = {
  "alias": "submittedTransactionsRecent",
  "args": [
    (v21/*: any*/),
    (v17/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "transactionsCount",
  "storageKey": "transactionsCount(monthsBack:6,viewName:\"buyerTracking.submitted\")"
},
v23 = {
  "alias": "confirmedTransactionsRecent",
  "args": [
    (v21/*: any*/),
    (v19/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "transactionsCount",
  "storageKey": "transactionsCount(monthsBack:6,viewName:\"buyerTracking.confirmed\")"
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "loginMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserLoginPayload",
        "kind": "LinkedField",
        "name": "userLogin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EthereumAddressType",
                "kind": "LinkedField",
                "name": "ethereumWalletAddress",
                "plural": false,
                "selections": [
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v18/*: any*/),
          (v20/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "loginMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserLoginPayload",
        "kind": "LinkedField",
        "name": "userLogin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EthereumAddressType",
                "kind": "LinkedField",
                "name": "ethereumWalletAddress",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v24/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              (v24/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v18/*: any*/),
          (v20/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/498.0.0-2025-02-14T14:08:37.801Z/loginMutation",
    "metadata": {},
    "name": "loginMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "429548daf5e297538d21cfe3fa2e792f";

export default node;
