/**
 * @generated SignedSource<<5b2639b10909717e15a892e053c6f6ff>>
 * @relayHash b3bf3c6fd3bea386fec33f56ba31615c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/498.0.0-2025-02-14T14:08:37.801Z/CartNavRefetchQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CartNavRefetchQuery$variables = {
  cartCountItems?: number | null;
  fetchLocalItems: boolean;
  fetchPortfolioItems: boolean;
  hasUserId: boolean;
  localItemIds?: ReadonlyArray<string | null> | null;
  userCountryCode?: string | null;
  userIds?: ReadonlyArray<string> | null;
};
export type CartNavRefetchQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"CartNav_viewer">;
  };
};
export type CartNavRefetchQuery = {
  response: CartNavRefetchQuery$data;
  variables: CartNavRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 0,
  "kind": "LocalArgument",
  "name": "cartCountItems"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchLocalItems"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchPortfolioItems"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "localItemIds"
},
v5 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userCountryCode"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userIds"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ConvertedAmountList",
  "kind": "LinkedField",
  "name": "convertedAmountList",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v11 = [
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalResults",
  "storageKey": null
},
v13 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "pdpURL",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "serviceId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isSold",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ItemPhoto",
    "kind": "LinkedField",
    "name": "photos",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ItemPhotoVersion",
        "kind": "LinkedField",
        "name": "versions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "webPath",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ImageVersionType",
            "kind": "LinkedField",
            "name": "type",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userCountryCode",
        "variableName": "userCountryCode"
      }
    ],
    "concreteType": "ItemPricing",
    "kind": "LinkedField",
    "name": "pricing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FinancialAmount",
        "kind": "LinkedField",
        "name": "netPrice",
        "plural": false,
        "selections": (v11/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FinancialAmount",
        "kind": "LinkedField",
        "name": "salePrice",
        "plural": false,
        "selections": (v11/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FinancialAmount",
        "kind": "LinkedField",
        "name": "retailPrice",
        "plural": false,
        "selections": (v11/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isAuctionEnabled",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AuctionLot",
    "kind": "LinkedField",
    "name": "relevantAuctionLot",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FinancialAmount",
        "kind": "LinkedField",
        "name": "buyItNowPrice",
        "plural": false,
        "selections": [
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AuctionSolution",
    "kind": "LinkedField",
    "name": "relevantAuctionSolution",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuctionBidsConnection",
        "kind": "LinkedField",
        "name": "bids",
        "plural": false,
        "selections": [
          (v12/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ],
    "storageKey": null
  },
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CartNavRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "cartCountItems",
                "variableName": "cartCountItems"
              },
              {
                "kind": "Variable",
                "name": "fetchLocalItems",
                "variableName": "fetchLocalItems"
              },
              {
                "kind": "Variable",
                "name": "fetchPortfolioItems",
                "variableName": "fetchPortfolioItems"
              },
              {
                "kind": "Variable",
                "name": "localItemIds",
                "variableName": "localItemIds"
              }
            ],
            "kind": "FragmentSpread",
            "name": "CartNav_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CartNavRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "isMultiItemCartFF",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "multiItemCart"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"multiItemCart\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CartTotals",
            "kind": "LinkedField",
            "name": "userCartTotals",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "savedItemsTotal",
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "condition": "fetchLocalItems",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 3
                  },
                  {
                    "kind": "Variable",
                    "name": "itemIds",
                    "variableName": "localItemIds"
                  }
                ],
                "concreteType": "ItemsConnection",
                "kind": "LinkedField",
                "name": "items",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Item",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  },
                  {
                    "kind": "Literal",
                    "name": "portfolioTypes",
                    "value": [
                      "CART"
                    ]
                  },
                  {
                    "kind": "Variable",
                    "name": "userIds",
                    "variableName": "userIds"
                  }
                ],
                "concreteType": "PortfoliosConnection",
                "kind": "LinkedField",
                "name": "portfolios",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfoliosEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Variable",
                                "name": "first",
                                "variableName": "cartCountItems"
                              }
                            ],
                            "concreteType": "PortfolioItemsConnection",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              {
                                "condition": "fetchPortfolioItems",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PortfolioItemsEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PortfolioItemV2",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Item",
                                            "kind": "LinkedField",
                                            "name": "item",
                                            "plural": false,
                                            "selections": (v13/*: any*/),
                                            "storageKey": null
                                          },
                                          (v7/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ]
                              }
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/498.0.0-2025-02-14T14:08:37.801Z/CartNavRefetchQuery",
    "metadata": {},
    "name": "CartNavRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "542ed3ae4243340c7cd633c6a9c32ed8";

export default node;
